/******************************************************************************/
/*               S E T    O F    U T I L S     F U N C T I O N S              */
/******************************************************************************/ 
  
  
/**
 * Convert Blob in base64
 * @param {*} blob 
 * @returns blob in base64
 */
function blobToBase64(blob) {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
        reader.onloadend = () => {
        resolve(reader.result);
        };
    });
};


/**
 * Convert a JSON Object into CSV
 * @param {*} objArray 
 * @returns 
 */
function convertJSONToCSV(objArray) {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';

    for (var i = 0; i < array.length; i++) {
        var line = '';
        for (var index in array[i]) {
            if (line !== '') line += ';'

            line += String(array[i][index]).replace(/;/g, ',')
                                           .replace(/\s+/g, " ")
                                           .replace(/\r?\n|\r/g, " ");
        }

        str += line + '\r\n';
    }

    return str;
}


/**
 * Count Words in a given string
 * @param {*} str the string
 * @returns number of words
 */
function WordCount(str) {
    return str.split(' ')
           .filter(function(n) { return n !== '' })
           .length;
}


export{ blobToBase64, convertJSONToCSV, WordCount}
import React from 'react'
import './Footer.css'


export default function Footer() {

    return (
        <div className="footer-contianer">
            Copyright © 2021 Lanzarote Art Gallery. Derechos Reservados <br/>
            Made with ♡ by <a href="https://ihm.solutions" target="_blank" rel="noreferrer">iHM Solutions</a>
        </div>
    )
}

/**
 * List of type of techniques
 * - the name_es attribute contains the spanish term
 * - the name_en attribute contains the english term 
 */
const TECHNIQUES_LIST = [
    {id:0,  name_es: 'Óleo',                   name_en: 'Oil painting'},
    {id:1,  name_es: 'Acrílico',               name_en: 'Acrylic'},
    {id:2,  name_es: 'T.Mixta'	,              name_en: 'Mixed T.'},
    {id:3,  name_es: 'Acuarela',               name_en: 'Watercolor'},
    {id:4,  name_es: 'Pastel',                 name_en: 'Pastel'},
    {id:5,  name_es: 'Cera',                   name_en: 'Wax'},
    {id:6,  name_es: 'Litografía',             name_en: 'Lithography'},
    {id:7,  name_es: 'Bronce',                 name_en: 'Bronze'},
    {id:8,  name_es: 'Abstracto',              name_en: 'Abstract'},
    {id:9,  name_es: 'Fotografía',             name_en: 'Photography'},
    {id:10, name_es: 'Aluminio',               name_en: 'Aluminium'},
    {id:11, name_es: 'Resina',                 name_en: 'Resin'},
    {id:12, name_es: 'Tintas de Alcohol',      name_en: 'Alcohol Inks'},
    {id:13, name_es: 'Tinta Esmalte',          name_en: 'Enamel Ink'},
    {id:14, name_es: 'Pigmentos de porcelana', name_en: 'Porcelain pigments'},
    {id:15, name_es: 'Tinta',                  name_en: 'Ink'},
    {id:16, name_es: 'Tinta y Rotulador',      name_en:'Ink and Crayons'},
    {id:17, name_es: 'Tinta y Crayons',        name_en: 'Ink and Crayons'},
    {id:18, name_es: 'Acrílico y Tinta',       name_en: 'Acrylic & Ink'},
    {id:19, name_es: 'Acrílico y Téc Mixta',   name_en: 'Acrylic and Mixed Technique'},
    {id:20, name_es: 'Acrílico y Collage',     name_en: 'Acrylic and Collage'},
    {id:21, name_es: 'Tinta y Acuarela',       name_en: 'Ink and Watercolour'},
    {id:22, name_es: 'Tinta y Lápiz',          name_en: 'Ink and Pencil'},
    {id:23, name_es: 'Lápiz carbón y carbonilla', name_en: 'Charcoal pencil'},
    {id:24, name_es: 'Rotulador',              name_en: 'Marker pen'}
];


/** List of type of Materials
 * - the name_es attribute contains the spanish term
 * - the name_en attribute contains the english term 
 */
const MATERIALS_LIST = [
    {id:0, name_es: 'Lienzo',      name_en: 'Canvas'},
    {id:1, name_es: 'Tabla',       name_en: 'Table'},
    {id:2, name_es: 'Papel',       name_en: 'Paper'},
    {id:3, name_es: 'Tejido',      name_en: 'Fabric'},
    {id:4, name_es: 'Madera',      name_en: 'Wood'},
    {id:5, name_es: 'Resina',      name_en: 'Resin'},
    {id:6, name_es: 'Aluminio',    name_en: 'Aluminium'},
    {id:7, name_es: 'Bronce',      name_en: 'Bronze'},
    {id:8, name_es: 'Metacrilato', name_en: 'Methacrylate'},
    {id:9, name_es: 'Porcelana',   name_en: 'Porcelana'},
    {id:10,name_es: 'Tela',        name_en: 'Canvas'},
    {id:11,name_es: 'Hoja de Oro', name_en: 'Gold Leaf'},
    {id:12, name_es: 'Cartón Pluma',   name_en: 'Featherboard'},
    {id:13, name_es: 'Impresión fotográfica sobre papel RC', name_en: 'Photographic print on RC paper'},
    {id:14, name_es: 'Impresión sobre papel Hahnemühle®', name_en: 'Printing on Hahnemühle® paper'} 
];


/** List of type of Artworks
 * 
 */
const ARTWORK_TYPES = [
    'Pintura',
    'Fotografía',
    'Escultura'
];


/** List of ID Types
 * 
 */
const ID_TYPES = [
    'DNI', 
    'NIE', 
    'Pasaporte'
];

const AppEnums = {
    TECHNIQUES_LIST, 
    MATERIALS_LIST, 
    ARTWORK_TYPES, 
    ID_TYPES 
};

// Export the constants 
export default AppEnums;
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";


const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
    // databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
    appId: process.env.REACT_APP_MESSAGING_APP_ID,
};

const app = firebase.initializeApp(firebaseConfig);

// app.auth().signInWithEmailAndPassword(process.env.REACT_APP_EMAIL, process.env.REACT_APP_PASSWORD)
//   .then((userCredential) => {
//     // Signed in
//     var user = userCredential.user;
//     // ...
//     console.log(user)
//   })
//   .catch((error) => {
//     var errorCode = error.code;
//     var errorMessage = error.message;
//     console.error(errorCode);
//     console.error(errorMessage);
//   });
  
// console.log("domain: ", process.env.REACT_APP_FIREBASE_DOMAIN);
// console.log("email: ",  process.env.REACT_APP_EMAIL);
// console.log("email: ",  process.env.REACT_APP_PASSWORD);

export const auth = app.auth();
export default firebase;